import { SubmissionError } from 'redux-form';
import Errors from '../constants/errors.json';
import { GET, POST } from '../utils/http';

export const setAuth = (auth) => ({
    type: 'SET_AUTH',
    ...auth,
});

export const resetAuth = () => ({
    type: 'RESET_AUTH',
});

export const login = (values) => async (dispatch) => {
    let response;

    try {
        response = await POST('/api/auth/login', values);
    } catch (e) {
        const errorText = 401 === e.status ? Errors.invalidCredentials : Errors.serverError;

        throw new SubmissionError({ _error: errorText });
    }

    if (!response.errors) {
        dispatch(setAuth(response));

        return response;
    }
    throw new SubmissionError(response.errors);
};

export const logout = () => async (dispatch) => {
    try {
        await GET('/api/auth/logout', null, { jsonify: false });
        dispatch(resetAuth());

        return { success: true };
    } catch (e) {
        return { success: false };
    }
};

export const signup = (values) => async (dispatch) => {
    let response;

    try {
        response = await POST('/api/user/', values);
    } catch (e) {
        const JSONResponse = await e.response.json();
        if ('ALREADY_EXISTS' === JSONResponse.email) {
            throw new SubmissionError({ email: 'emailAlreadyUsed' });
        }

        throw new SubmissionError({ _error: Errors.serverError });
    }

    if (!response.errors) {
        dispatch(setAuth(response));
    } else {
        throw new SubmissionError(response.errors);
    }
};

export const checkToken = () => async (dispatch) => {
    try {
        const response = await GET('/api/auth/checkToken');
        if (!response.valid) {
            dispatch(logout());
        }

        return { success: true };
    } catch (e) {
        return { success: false };
        // throw new SubmissionError({ _error: Errors.serverError });
        // TODO::handle error ?
    }
};

export const currentUser = () => async (dispatch) => {
    try {
        const response = await GET('/api/auth/me');
        if (response && response.id) {
            dispatch(setAuth(response));
        }

        return { success: true };
    } catch (e) {
        return { success: false };
        // console.info('user no logged ');
        // TODO::handle error ?
    }
};

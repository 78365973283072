const initialState = {
    id: null,
    email: null,
    name: null,
    status: null,
    surname: null,
    token: null,
    isFirstConnection: null,
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, { type, ...payload }) => {
    switch (type) {
        case 'SET_AUTH':
            document.cookie = `auth_token=${payload.token};path=/`;

            return {
                ...state,
                id: payload.id,
                email: payload.email,
                name: payload.name,
                status: payload.status,
                language: payload.language,
                surname: payload.surname,
                token: payload.token,
                isFirstConnection: payload.isFirstConnection,
                origin: payload.origin,
                connectId: payload.connectId,
            };
        case 'RESET_AUTH':
            document.cookie = 'auth_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';

            return initialState;
        default:
            return state;
    }
};

export default reducer;

import { createContext, useContext } from 'react';

export const MenuContext = createContext({
    menuIsOpen: false,
    openMenu: () => {},
});

export const useMenu = () => useContext(MenuContext);

export default MenuContext;

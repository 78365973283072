import { SubmissionError } from 'redux-form';
import Errors from '../constants/errors.json';
import { PATCH, POST } from '../utils/http';
import { setAuth } from './auth';

export const passwordLost = (values) => async () => {
    try {
        await POST('/api/user/password-lost', values);
    } catch (e) {
        const errorText = 404 === e.status ? Errors.accountNotFound : Errors.serverError;

        throw new SubmissionError({ _error: errorText });
    }
};

export const updatePassword = (password, token) => async (dispatch) => {
    try {
        const response = await PATCH('/api/user/password', {
            password,
            token,
        });

        dispatch(setAuth(response));
    } catch (e) {
        const errorText = 404 === e.status ? Errors.invalidToken : Errors.serverError;
        throw new SubmissionError({ _error: errorText });
    }
};
